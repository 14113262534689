import { ChevronLeftIcon, ClockIcon } from "@heroicons/react/outline";
import dayjs from "dayjs";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { displayDate, displayTimestamp } from "../action";
import {
  completeInspection,
  getInspectionTaskDetails,
  getInspectionTasks,
  startInspection,
  updateInspectionReport,
  uploadInspectionImages,
} from "../action/actionStaff";
import useSubmitWithToast from "../action/useSubmitWithToast";
import { Button } from "../components";
import { LoadingContent } from "../components/Loading";
import { PropertyContext } from "../context/property";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../components/Modal";
import {
  CheckCircleIcon,
  MinusIcon,
  PlusIcon,
  XCircleIcon,
} from "@heroicons/react/solid";
import FileUpload from "../components/FileUpload";
import { TodoList } from "../components/TodoList";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function Inspection({
  propertyId,
  staffId,
}: {
  propertyId: string;
  staffId: string;
}) {
  const [selectedRoomId, setSelectedRoomId] = useState("");
  const [selectedReservation, setSelectedReservation] = useState({});

  return (
    <>
      <header>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold leading-tight text-gray-900">
            Inspection
            <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-pink-100 text-pink-800 ml-2">
              BETA
            </span>
          </h1>
        </div>
      </header>
      <main>
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="px-4 py-8 sm:px-0">
            {selectedRoomId ? (
              <InspectionSingleTask
                roomId={selectedRoomId}
                selectedReservation={selectedReservation}
                propertyId={propertyId}
                staffId={staffId}
                setSelectedRoomId={setSelectedRoomId}
                setSelectedReservation={setSelectedReservation}
              />
            ) : (
              <InspectionTaskList
                propertyId={propertyId}
                staffId={staffId}
                setSelectedRoomId={setSelectedRoomId}
                setSelectedReservation={setSelectedReservation}
              />
            )}
          </div>
        </div>
      </main>
    </>
  );
}

const InspectionTaskList = ({
  propertyId,
  staffId,
  setSelectedRoomId,
  setSelectedReservation,
}: {
  propertyId: string;
  staffId: string;
  setSelectedRoomId: any;
  setSelectedReservation: any;
}) => {
  const [isLoading, setLoading] = useState(true);
  const { setLoggedIn } = useContext(PropertyContext);
  const [tasks, setTasks] = useState([]);
  const [activeTab, setActiveTab] = useState("today");

  const tabs = [
    { value: "today", name: "Today" },
    { value: "tomorrow", name: "Tomorrow" },
    { value: "next7days", name: "Next 7 days" },
    { value: "next30days", name: "Next 30 days" },
    { value: "next90days", name: "Next 90 days" },
  ];

  const handleTasks = async () => {
    const code = localStorage.getItem("code") || "";
    setTasks([]);
    setLoading(true);

    const today = dayjs().format("YYYY-MM-DD");
    const tomorrow = dayjs().add(1, "days").format("YYYY-MM-DD");

    try {
      if (activeTab === "today") {
        const tasks2 = await getInspectionTasks({
          propertyId,
          startDate: today,
          endDate: today,
          staffId,
          code,
        });
        setTasks(tasks2);
      }

      if (activeTab === "tomorrow") {
        const tasks2 = await getInspectionTasks({
          propertyId,
          startDate: tomorrow,
          endDate: tomorrow,
          staffId,
          code,
        });
        setTasks(tasks2);
      }

      if (activeTab === "next7days") {
        const startDate = tomorrow;
        const endDate = dayjs().add(7, "days").format("YYYY-MM-DD");
        const tasks2 = await getInspectionTasks({
          propertyId,
          startDate: startDate,
          endDate: endDate,
          staffId,
          code,
        });
        setTasks(tasks2);
      }

      if (activeTab === "next30days") {
        const startDate = tomorrow;
        const endDate = dayjs().add(30, "days").format("YYYY-MM-DD");
        const tasks2 = await getInspectionTasks({
          propertyId,
          startDate: startDate,
          endDate: endDate,
          staffId,
          code,
        });
        setTasks(tasks2);
      }

      if (activeTab === "next90days") {
        const startDate = tomorrow;
        const endDate = dayjs().add(90, "days").format("YYYY-MM-DD");
        const tasks2 = await getInspectionTasks({
          propertyId,
          startDate: startDate,
          endDate: endDate,
          staffId,
          code,
        });
        setTasks(tasks2);
      }
      setLoading(false);
    } catch (error) {
      setLoggedIn(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleTasks();
  }, [activeTab]);

  return (
    <>
      {/* Tabs */}
      <div className="flex flex-col sm:flex-row sm:justify-between mb-4">
        <div className="mb-4 sm:mb-0">
          <div className="sm:hidden">
            <label htmlFor="tabs" className="sr-only">
              Select a tab
            </label>
            {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
            <select
              id="tabs"
              name="tabs"
              className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
              value={activeTab}
              onChange={(e) => setActiveTab(e.target.value)}
            >
              {tabs.map((tab) => (
                <option key={tab.value} value={tab.value}>
                  {tab.name}
                </option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block">
            <nav className="flex space-x-4" aria-label="Tabs">
              {tabs.map((tab) => (
                <span
                  key={tab.value}
                  onClick={() => setActiveTab(tab.value)}
                  className={classNames(
                    tab.value === activeTab
                      ? "bg-indigo-100 text-indigo-700"
                      : "text-gray-500 hover:text-gray-700",
                    "px-3 py-2 font-medium text-sm rounded-md cursor-pointer"
                  )}
                  aria-current={tab.value === activeTab ? "page" : undefined}
                >
                  {tab.name}
                </span>
              ))}
            </nav>
          </div>
        </div>

        <div>
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-indigo-100 text-indigo-800">
            {tasks?.length} tasks
          </span>
        </div>
      </div>
      {/* Today */}

      {isLoading ? (
        <LoadingContent />
      ) : (
        <InspectionTable
          tasks={tasks}
          setSelectedRoomId={setSelectedRoomId}
          setSelectedReservation={setSelectedReservation}
          isOverdue={activeTab !== "today" && activeTab !== "tomorrow"}
        />
      )}
    </>
  );
};

const InspectionTable = ({
  tasks,
  setSelectedRoomId,
  setSelectedReservation,
  isOverdue,
}: {
  tasks: any;
  setSelectedRoomId: any;
  setSelectedReservation: any;
  isOverdue: boolean;
}) => {
  return (
    <table className="min-w-full divide-y divide-gray-300">
      <thead className="bg-gray-50">
        <tr>
          {isOverdue && (
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Date
            </th>
          )}
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Room
          </th>
          <th
            scope="col"
            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
          >
            Housekeeping Status
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          ></th>
          <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
            <span className="sr-only">Edit</span>
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">
        {tasks
          .sort((a: any, b: any) =>
            a.checkIn === b.checkIn
              ? a.roomName > b.roomName
                ? 1
                : -1
              : a.checkIn > b.checkIn
              ? 1
              : -1
          )
          .map((task: any) => (
            <InspectionTableRow
              task={task}
              key={task.id}
              setSelectedRoomId={setSelectedRoomId}
              setSelectedReservation={setSelectedReservation}
              isOverdue={isOverdue}
            />
          ))}
      </tbody>
    </table>
  );
};

const InspectionTableRow = ({
  task,
  setSelectedRoomId,
  setSelectedReservation,
  isOverdue,
}: {
  task: any;
  setSelectedRoomId: any;
  setSelectedReservation: any;
  isOverdue: boolean;
}) => {
  const {
    id,
    roomId,
    checkIn,
    roomName,
    roomTypeName,
    isStarted,
    isEnded,
    isInspectionDone,
    housekeepingStatus,
  } = task;

  const isInProgress = !!isStarted && !isEnded;

  return (
    <tr key={id}>
      {isOverdue && (
        <td className="px-3 py-4 text-sm text-gray-700">
          {displayDate(checkIn)}
        </td>
      )}

      <td className="px-3 py-4 text-sm text-gray-700">
        {roomName}
        {isInProgress && (
          <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800 ml-2">
            <svg
              className="-ml-0.5 mr-1.5 h-2 w-2 text-green-400"
              fill="currentColor"
              viewBox="0 0 8 8"
            >
              <circle cx={4} cy={4} r={3} />
            </svg>
            In Progress
          </span>
        )}
        <dd className="text-gray-500 text-xs mt-1">{roomTypeName}</dd>
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {housekeepingStatus && <BadgeHousekeepingStatus task={task} />}
      </td>
      <td className="px-3 py-4 text-sm text-gray-500"></td>
      <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        {isInspectionDone ? (
          <div>Completed</div>
        ) : (
          <a
            href="#"
            className="text-indigo-600 hover:text-indigo-900"
            onClick={() => {
              setSelectedRoomId(roomId);
              setSelectedReservation(task);
            }}
          >
            Details
          </a>
        )}
      </td>
    </tr>
  );
};

const InspectionSingleTask = ({
  roomId,
  selectedReservation,
  propertyId,
  staffId,
  setSelectedRoomId,
  setSelectedReservation,
}: {
  roomId: string;
  selectedReservation: any;
  propertyId: string;
  staffId: string;
  setSelectedRoomId: any;
  setSelectedReservation: any;
}) => {
  const [isLoading, setLoading] = useState(true);
  const { staff, setLoggedIn } = useContext(PropertyContext);
  const [task, setTask]: [any, any] = useState(null);
  const [comments, setComments] = useState("");
  const [summaryImages, setSummaryImages] = useState<string[]>([]);
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  const [markRoomReady, setMarkRoomReady] = useState(true);
  const [isReady, setIsReady] = useState(true);

  const {
    guide,
    roomName,
    roomTypeName,
    report,
    history,
    roomDefaultInspector,
  } = task || {};

  const {
    id: reservationId,
    housekeepingStatus,
    preArrivalCheckStaffId,
  } = selectedReservation;
  const { isStarted, isEnded, results = {} } = report || {};

  const isTaskDue = housekeepingStatus === "clean";

  // The default inspector for a room will always see the task.
  // If the task is specifically assigned to someone,
  // then it must be that staff to start the task.
  // But if it's not assigned, the default inspector can start the task.
  const canStaffStartInspection = !!preArrivalCheckStaffId
    ? preArrivalCheckStaffId === staffId
    : roomDefaultInspector === staffId;

  const isInProgress = !!isStarted && !isEnded;

  const guideList = Object.entries(guide || {})
    .map(([id, guideItem]) => {
      if (typeof guideItem === "object") {
        return { id, ...guideItem };
      }
    })
    // filter guide without the name
    // a name is a mandatory field  for guide
    // but edge case is when user set up guide
    // they upload the image but not save the guide
    .filter((guide: any) => !!guide?.name);

  const taskCount = Object.values(results).filter(
    (result: any) => !!result?.name
  ).length;

  const doneCount = Object.values(results).filter(
    (result: any) => result?.status === "done"
  ).length;

  useEffect(() => {
    const images = results?.summary?.images || [];
    setSummaryImages(images);
  }, [task]);

  const handleInspectionTask = async () => {
    try {
      setLoading(true);
      const task2 =
        (await getInspectionTaskDetails({
          propertyId,
          roomId,
          staffId,
          reservationId,
        })) || {};
      setTask(task2);
      setLoading(false);
    } catch (error) {
      setLoggedIn(false);
      setLoading(false);
    }
  };

  const [handleStartInspection] = useSubmitWithToast({
    func: () => {
      return startInspection({
        propertyId,
        reservationId,
        staffId,
        roomId,
        guide,
      });
    },
    successMessage: "The task starts now.",
    successFunc: () => {
      handleInspectionTask();
    },
  });

  const handleUpdateInspectionReport = async ({
    result,
    itemId,
  }: {
    result: any;
    itemId: string;
  }) => {
    setTask({
      ...task,
      report: {
        ...task?.report,
        results: {
          ...task?.report?.results,
          [itemId]: { ...task?.report?.results?.[itemId], ...result },
        },
      },
    });

    try {
      await updateInspectionReport({
        propertyId,
        reservationId,
        staffId,
        roomId,
        guideId: itemId,
        guide,
        result,
      });
    } catch (err: any) {
      alert(err?.message);
      throw Error(err?.message);
    }
    return Promise.resolve();
  };

  const [handleCompleteTask] = useSubmitWithToast({
    func: async () => {
      try {
        await completeInspection({
          propertyId,
          reservationId,
          staffId,
          staff,
          roomId,
          results,
          markRoomReady,
        });
      } catch (err: any) {
        alert(err?.message);
        throw Error(err?.message);
      }

      return Promise.resolve();
    },
    successMessage: "The task is successfully completed.",
    successFunc: () => {
      handleInspectionTask();
      setSelectedRoomId("");
      setSelectedReservation({});
    },
    loadingText: "Submitting your inspection record...",
  });

  const handleUploadSummaryImages = async (files: any) => {
    const urls = await uploadInspectionImages({ files, propertyId });
    await handleUpdateInspectionReport({
      result: { images: [...summaryImages, ...urls] },
      itemId: "summary",
    });
  };

  const handleDeleteSummaryImage = async (url: string) => {
    const newSummaryImages = summaryImages.filter(
      (imageUrl) => imageUrl !== url
    );

    await handleUpdateInspectionReport({
      result: { images: newSummaryImages },
      itemId: "summary",
    });
  };

  useEffect(() => {
    handleInspectionTask();
  }, [roomId]);

  useEffect(() => {
    handleUpdateInspectionReport({
      result: { comments },
      itemId: "summary",
    });
  }, [comments]);

  return (
    <div className="">
      {isLoading ? (
        <LoadingContent />
      ) : (
        <>
          <button
            type="button"
            className="mb-4 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => {
              setSelectedRoomId("");
              setSelectedReservation({});
            }}
          >
            <ChevronLeftIcon className="h-4 w-4 mr-1" /> Go back
          </button>
          <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div
              className={`px-4 py-5 sm:px-6 flex flex-col sm:flex-row sm:justify-between ${
                isInProgress ? "text-white bg-green-500" : "text-auto bg-white"
              }`}
            >
              <div className="flex flex-col items-start">
                <h3
                  className={`text-lg leading-6 font-medium ${
                    !isInProgress ? "text-gray-700" : "text-white"
                  }`}
                >
                  {roomName}
                </h3>
                <p
                  className={`mt-1 max-w-2xl text-sm ${
                    !isInProgress ? "text-gray-500" : "text-white"
                  }`}
                >
                  {roomTypeName}
                </p>
                {isInProgress && (
                  <p className="mt-2 mb-2 sm:mb-0 max-w-2xl text-sm bg-green-700 p-1 rounded">{`${doneCount} out of ${taskCount} completed`}</p>
                )}
              </div>

              <div>
                <dd
                  className={`${
                    isInProgress ? "text-white " : "text-indigo-600"
                  } text-xl sm:text-right mb-1`}
                >
                  <Countdown report={report} />
                </dd>
                <dd>
                  <button
                    type="button"
                    className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-50 px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                    onClick={() => {
                      setIsHistoryModalOpen(true);
                    }}
                  >
                    <ClockIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                    History
                  </button>
                </dd>
              </div>
            </div>
            <div className="border-t border-gray-200 p-0">
              <dl className="divide-y divide-gray-200">
                <TodoList
                  itemName="inspection guide"
                  itemList={guideList}
                  isDescriptionHTML={true}
                  editMode={isInProgress}
                  handleItemChange={handleUpdateInspectionReport}
                  results={results}
                  propertyId={propertyId}
                />
                {isTaskDue && canStaffStartInspection && (
                  <>
                    {isInProgress ? (
                      <>
                        <div className="flex flex-col px-8 justify-center items-center sm:items-start">
                          <div className="mt-4 w-full">
                            <label className="mb-2 block text-center sm:text-left">
                              General Comments
                            </label>
                            <textarea
                              rows={4}
                              name="comment"
                              id="comment"
                              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              placeholder="Enter your inspection comments"
                              value={comments}
                              onChange={(e) => setComments(e.target.value)}
                            />
                          </div>
                          <div className="my-6 w-full">
                            <label className="mb-2 block text-center sm:text-left">
                              Images
                            </label>

                            <FileUpload
                              uploadFunc={handleUploadSummaryImages}
                              accept="image/*"
                              fileUrls={summaryImages}
                              handleDeleteFunc={handleDeleteSummaryImage}
                              setIsReady={setIsReady}
                              label="Upload Images"
                            />
                          </div>
                        </div>

                        <div className="py-4 flex-col text-center">
                          <div className="flex justify-center mb-3">
                            <input
                              type="checkbox"
                              className="my-auto h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                              checked={markRoomReady}
                              onChange={(e) => {
                                setMarkRoomReady(e.target.checked);
                              }}
                            />
                            <label className="font-medium text-gray-900 text-sm ml-1">
                              Mark Room As Ready
                            </label>
                          </div>
                          <Button
                            onClick={handleCompleteTask}
                            className="mx-auto"
                            disabled={!isReady}
                          >
                            Complete
                          </Button>
                        </div>
                      </>
                    ) : (
                      <div className="py-4 grid place-content-center">
                        <Button onClick={handleStartInspection}>
                          Start Inspection
                        </Button>
                      </div>
                    )}
                  </>
                )}
              </dl>
            </div>
          </div>
          <div className="text-xs text-gray-500 mt-6 flex flex-col md:flex-row">
            <span className="mr-4">Room ID: {roomId}</span>
            <span className="mr-4">Reservation ID: {reservationId}</span>
          </div>
          <InspectionHistoryModal
            setOpen={setIsHistoryModalOpen}
            isOpen={isHistoryModalOpen}
            roomName={roomName}
            history={history}
          />
        </>
      )}
    </div>
  );
};

export const Countdown = ({ report }: { report: any }) => {
  const [time, setTime] = useState(0);
  const { isStarted, isEnded, timestampStarted } = report || {};

  const isInProgress = !!isStarted && !isEnded;

  useEffect(() => {
    if (timestampStarted?._seconds && isInProgress) {
      const duration = -dayjs
        .unix(timestampStarted?._seconds)
        .diff(new Date(), "seconds");
      if (duration >= 0) {
        const tick = () => setTime(+(duration || 0) + 1);
        const timerId = setInterval(() => tick(), 1000);
        return () => clearInterval(timerId);
      }
    }
  }, [timestampStarted?._seconds, time]);

  const minutes = Math.floor(time / 60);

  if (!isInProgress) {
    return null;
  }

  return <span>{minutes} mins</span>;
};

export const BadgeHousekeepingStatus = ({ task }: { task: any }) => {
  const { housekeepingStatus } = task || {};
  if (housekeepingStatus === "dirty") {
    return (
      <span>
        <span className="inline-flex items-center px-1 py-0.5 mr-1 rounded text-xs font-medium bg-red-100 text-red-800">
          Dirty
        </span>
      </span>
    );
  } else if (housekeepingStatus === "clean") {
    return (
      <span>
        <span className="inline-flex items-center px-1 py-0.5 mr-1 rounded text-xs font-medium bg-green-100 text-green-800">
          Clean
        </span>
      </span>
    );
  }
  return (
    <span className="capitalize inline-flex items-center px-1 py-0.5 mr-1 rounded text-xs font-medium bg-blue-100 text-blue-800">
      {housekeepingStatus}
    </span>
  );
};

const InspectionHistoryModal = ({
  isOpen,
  setOpen,
  roomName,
  history,
}: {
  isOpen: boolean;
  setOpen: any;
  roomName: string;
  history: any;
}) => {
  const [selectedImageUrl, setSelectedImageUrl] = useState("");
  const [isImageDetailModalOpen, setIsImageDetailModalOpen] = useState(false);
  const toggle = () => setOpen(!isOpen);

  const handleClose = () => {
    toggle();
  };

  return (
    <>
      <Modal open={isOpen} setOpen={setOpen} size="lg">
        <ModalHeader onClose={toggle}>
          {roomName} Inspection History
        </ModalHeader>
        <ModalBody>
          {history.length > 0 ? (
            <InspectionHistoryTable
              reportList={history}
              setSelectedImageUrl={setSelectedImageUrl}
              setImageModalOpen={setIsImageDetailModalOpen}
            />
          ) : (
            <div>No inspection history is found.</div>
          )}
        </ModalBody>
        <ModalFooter>
          <div></div>
          <Button onClick={handleClose}>Close</Button>
        </ModalFooter>
        <ImageModal
          isOpen={isImageDetailModalOpen}
          setOpen={setIsImageDetailModalOpen}
          url={selectedImageUrl}
        />
      </Modal>
    </>
  );
};

const InspectionHistoryTable = ({
  reportList,
  setSelectedImageUrl,
  setImageModalOpen,
}: {
  reportList: any;
  setSelectedImageUrl: any;
  setImageModalOpen: any;
}) => {
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full">
              <thead className="bg-white">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Comments
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {reportList.map((singleReport: any) => {
                  return (
                    <InspectionHistoryTableGroup
                      setSelectedImageUrl={setSelectedImageUrl}
                      singleReport={singleReport}
                      setImageModalOpen={setImageModalOpen}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const InspectionHistoryTableGroup = ({
  singleReport,
  setSelectedImageUrl,
  setImageModalOpen,
}: {
  singleReport: any;
  setSelectedImageUrl: any;
  setImageModalOpen: any;
}) => {
  const [expanded, setExpanded] = useState(false);

  const { timestampEnded, staffName, results, reservationId } = singleReport;

  const doneCount = Object.values(results).filter(
    (result: any) => result?.status === "done"
  ).length;

  const undoneCount = Object.entries(results).filter(
    ([id, result]: any) => id !== "summary" && result?.status !== "done"
  ).length;

  return (
    <Fragment key={reservationId}>
      <tr
        className="border-t border-gray-500 cursor-pointer"
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        <th
          colSpan={2}
          scope="colgroup"
          className="md:whitespace-nowrap bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
        >
          {doneCount} done | {undoneCount} undone
          <small className="ml-3">
            {staffName} {displayTimestamp(timestampEnded)}
          </small>
        </th>
        <th className="bg-gray-50 py-2 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-3">
          <div
            className="cursor-pointer float-right"
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            {expanded ? (
              <MinusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
            ) : (
              <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
            )}
          </div>
        </th>
      </tr>
      {Object.entries(results)
        .sort((a: any, b: any) => {
          // if the result id is "summary", make it as the last
          if (a[0] === "summary") {
            return 1;
          }
          if (b[0] === "summary") {
            return -1;
          }
          // otherwise sort it by order
          const orderA = Number(a[1]?.order);
          const orderB = Number(b[1]?.order);

          return orderA > orderB ? 1 : -1;
        })
        .map(([guideId, result]: any) => {
          return (
            <InspectionHistoryTableRow
              guideId={guideId}
              guideResult={result}
              expanded={expanded}
              setSelectedImageUrl={setSelectedImageUrl}
              setImageModalOpen={setImageModalOpen}
            />
          );
        })}
    </Fragment>
  );
};

const InspectionHistoryTableRow = ({
  guideId,
  guideResult,
  expanded,
  setSelectedImageUrl,
  setImageModalOpen,
}: {
  guideId: string;
  guideResult: {
    name: string;
    images: string[];
    comments: string;
    status: string;
  };
  expanded: boolean;
  setSelectedImageUrl: any;
  setImageModalOpen: any;
}) => {
  const { name, images = [], comments, status } = guideResult;

  const handleClick = (url: string) => {
    setSelectedImageUrl(url);
    setImageModalOpen(true);
  };

  return (
    <>
      <tr
        className={classNames(expanded ? "border-gray-200 border-t" : "hidden")}
        key={guideId}
      >
        {status === "done" && (
          <>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
              <CheckCircleIcon
                className="-ml-0.5 h-5 w-5 text-green-500"
                aria-hidden="true"
              />
            </td>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
              {name}
            </td>
          </>
        )}

        {status !== "done" && guideId !== "summary" && (
          <>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
              <XCircleIcon
                className="-ml-0.5 h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            </td>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
              {name}
            </td>
          </>
        )}

        {guideId === "summary" && (
          <td
            colSpan={2}
            className="whitespace-nowrap font-bold py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3"
          >
            Summary
          </td>
        )}

        <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3 whitespace-pre-wrap">
          {comments}
        </td>
      </tr>
      {images.length > 0 && (
        <tr className={classNames(expanded ? "mt-0" : "hidden")}>
          <td colSpan={4}>
            <ul
              role="list"
              className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-6 xl:gap-x-8"
            >
              {images.map((url: string, index) => {
                return (
                  <li key={index} className="relative p-1">
                    <div
                      onClick={() => handleClick(url)}
                      className="cursor-pointer border-2 border-gray-300 border-dashed rounded-lg block w-full aspect-w-10 aspect-h-7 rounded-lg bg-white overflow-hidden"
                    >
                      <img
                        src={url}
                        alt={`${name} report image`}
                        className="object-cover pointer-events-none"
                      />
                    </div>
                  </li>
                );
              })}
            </ul>
          </td>
        </tr>
      )}
    </>
  );
};

const ImageModal = ({
  isOpen,
  setOpen,
  url,
  description,
}: {
  isOpen: boolean;
  setOpen: any;
  url: string;
  description?: string;
}) => {
  const toggle = () => setOpen(!isOpen);

  return (
    <>
      <Modal open={isOpen} setOpen={setOpen}>
        <ModalHeader onClose={toggle}>Image Detail</ModalHeader>
        <ModalBody>
          {<img alt={description || ""} src={url}></img>}
          {!!description && <div className="mt-2">{description}</div>}
        </ModalBody>
      </Modal>
    </>
  );
};
